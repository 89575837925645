// nav-list
mainMenu('.nav-list','.nav-toggle'); 
function mainMenu(menu,btn){ 
	var menu = $(menu), 
	btn = $(btn); 
	btn.on('click',function(){ 
		btn.blur(); 
		if(btn.hasClass('disable')){ 
			btn.removeClass('disable') 
			btn.attr("data-disable","false") 
			menu.removeClass('disable'); 
			menu.slideDown(300); 
		}else{ 
			btn.addClass('disable') 
			btn.attr("data-disable","true") 
			menu.addClass('disable'); 
			menu.slideUp(300); 
		} 
	}) 
	$(btn).attr("data-disable","true"); 
	toggleMenu(btn,menu); 
	$(window).resize(function(){ 
		toggleMenu(btn,menu); 
	}) 
	function toggleMenu(btn,menu){ 
		if($(btn).is(":visible") && $(btn).attr("data-disable")=="true"){ 
			$(btn).addClass('disable'); 
			$(menu).addClass('disable'); 
			$(menu).hide(); 
		}else if(!$(btn).is(":visible")){ 
			$(btn).removeClass('disable'); 
			$(menu).removeClass('disable'); 
			$(menu).show(); 
		} 
	} 
}

// nav-toggle
$('.nav-toggle').on('click', function(){
	$(this).toggleClass('nav-toggle--open')
})