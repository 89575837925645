//= ../../../bower_components/jquery/dist/jquery.min.js
//= ../../../bower_components/slick-carousel/slick/slick.min.js
//= ../../../bower_components/fancybox/source/jquery.fancybox.pack.js
$(function(){

	$(document).click(function(event) {
		if ($(event.target).closest(".c-select").length) return;
		$(".c-select-list").hide();
		event.stopPropagation();

		if ($(event.target).closest(".nav__wrap").length) return;
		$(".nav-search").hide();
		event.stopPropagation();

		$('.nav__wrap>.nav__btn').show()
	});
	$('.js-select').each(function(){
		$(this).find('.c-select__current').click(function(){
			$(this).siblings('.c-select-list').stop().fadeToggle();
		})

		$(this).find('.c-select-list__btn').click(function(){
			var text = $(this).text();
			$('.c-select-list__item').not($(this).parent()).removeClass('is-active');
			$(this).parent().addClass('is-active');
			$(this).parents('.c-select-list').hide().siblings('.c-select__current').attr('data-val', text).text(text);
		})
	})
	$('.js-btn-categories').click(function(e){
		e.preventDefault();

		$('#js-sidebar').stop().slideToggle();
	})
	$('#js-slider').slick({
		arrows: false,
		dots: true
	})

	$('.header__call').fancybox()
	$('a.header__btn').fancybox()